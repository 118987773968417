<header id="main-header">
  <nav class="navbar navbar-expand-lg">
    <div class="container flex-lg-row flex-nowrap align-items-center position-relative">
      <div class="navbar-brand w-100 m-0">
        <figure id="logotype" class="m-0 h-50" routerLink="/" role="button">
          <img src="./assets/images/logotype.jpeg" alt="" class="w-100 h-100">
        </figure>
      </div>

      <div class="w-100 d-none d-lg-block">
        <ng-container *ngTemplateOutlet="mainMenu"></ng-container>
      </div>

      <div class="navbar-other w-100 d-flex ms-auto">
        <ul class="navbar-nav flex-row align-items-center ms-auto">
          <li class="nav-item mx-2">
            <a routerLink="/products" class="btn btn-lg btn-outline-primary rounded-5 w-max-content">
              <small>Tienda en línea</small>
            </a>
          </li>
          <li class="nav-item d-block d-lg-none">
            <p-button icon="pi pi-bars" [text]="true" (click)="open(offcanvas)"></p-button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>

<ng-template #mainMenu>
  <ul class="navbar-nav justify-content-center">
    <li class="nav-item">
      <a class="nav-link fw-medium w-max-content" routerLink="/" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }">Inicio</a>
    </li>
    <!-- <li class="nav-item dropdown-mega" ngbDropdown>
      <button type="button" class="nav-link" ngbDropdownToggle>Productos</button>

      <ul ngbDropdownMenu class="mega-menu bg-light">
        <li class="mega-menu-content mega-menu-scroll">
          <ul class="row row-cols-1 row-cols-lg-4 row-cols-xl-6 gx-0 gx-lg-4 gy-lg-2 list-unstyled">
            @for (item of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24]; track $index) {
            <li class="col">
              <a ngbDropdownItem role="button">
                <figure class="d-none d-lg-block mb-2 bg-dark-subtle rounded-3 p-4" style="height: 120px;">
                  <img class="w-100" src="./assets/images/resources/block10.svg" alt="">
                </figure>
                <span class="px-2">Menú {{item}}</span>
              </a>
            </li>
            }
          </ul>

          <span class="d-none d-lg-flex">
            <i class="bi bi-arrow-down-up me-2"></i><strong>Scrollea para ver más</strong>
          </span>
        </li>
      </ul>
    </li> -->
    <li class="nav-item">
      <a routerLink="/products" routerLinkActive="active" class="nav-link">Productos</a>
    </li>
    <!-- <li class="nav-item">
      <a routerLink="/aboutus" routerLinkActive="active" class="nav-link">Nosotros</a>
    </li> -->
  </ul>
</ng-template>

<ng-template #offcanvas let-offcanvas>
  <div class="offcanvas-header justify-content-between">
    <figure id="logotype" class="m-0" [routerLink]="'.'" style="width: 150px;">
      <img src="./assets/images/logotype.jpeg" alt="Logo" class="w-100">
    </figure>
    <p-button icon="pi pi-times" [rounded]="true" [text]="true" (click)="offcanvas.dismiss()"></p-button>
  </div>
  <div class="offcanvas-body d-flex flex-column h-100">
    <ng-container *ngTemplateOutlet="mainMenu"></ng-container>

    <div class="offcanvas-footer">
      <div>
        <a href="mailto:ventas@vantapsa.com" class="link-inverse">{{'ventas@vantapsa.com'}}</a>
        <br> +52 998 319 8280 <br>
        <!-- <nav class="nav social mt-4">
          <a role="button"><i class="bi bi-twitter-x"></i></a>
          <a role="button"><i class="bi bi-facebook"></i></a>
          <a role="button"><i class="bi bi-instagram"></i></a>
          <a role="button"><i class="bi bi-threads"></i></a>
          <a role="button"><i class="bi bi-tiktok"></i></a>
        </nav> -->
      </div>
    </div>
  </div>
</ng-template>