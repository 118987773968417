<section class="text-bg-light py-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 d-flex flex-column justify-content-center mb-3">
        <!-- <p class="fs-5 mb-2 opacity-50 text-uppercase">Linea de servicio</p> -->
        <h3 class="fs-1 mb-2 section-title">¿Necesitas ayuda?</h3>
        <p class="fs-5 fw-light m-0">Siempre estamos listos para atenderte.</p>
      </div>
      <div class="col-lg-6 d-flex flex-column justify-content-center">
        <p class="fw-medium m-0">Horario de atención</p>
        <p class="fw-medium m-0">Lunes a Viernes de 9:00 am a 6:00 pm</p>
        <p class="fw-medium">Sábado de 8:00 am a 1:00 pm</p>
        <p class="d-flex align-items-center m-0 gap-3">
          <i class="bi bi-envelope-at fs-4"></i> <span>{{'ventas@vantapsa.com'}}</span>
        </p>
        <!-- <p class="d-flex align-items-center m-0 gap-3">
          <i class="bi bi-whatsapp fs-4"></i> <span>(998) 319-8280</span>
        </p> -->

        <div class="mt-2">
          <a href="https://api.whatsapp.com/send?phone=5219983198280" target="_blank"
            class="btn btn-outline-dark rounded-5 px-4 py-2">Escribenos un WhatsApp</a>
        </div>
      </div>
    </div>
  </div>
</section>